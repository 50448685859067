import '../css/ckeditor'

import { Essentials } from '@ckeditor/ckeditor5-essentials'
import { Paragraph } from '@ckeditor/ckeditor5-paragraph'
import { Code, Bold, Italic, Underline, Strikethrough, Subscript, Superscript } from '@ckeditor/ckeditor5-basic-styles'

export default {
  plugins: [Essentials, Paragraph, Code, Bold, Italic, Underline, Strikethrough, Subscript, Superscript],
  toolbar: ['code',  '|',  'bold',  'italic',  'underline',  'strikethrough',  '|',  'subscript',  'superscript'],
  language: 'en',
  licenseKey: 'GPL'
}
